export const Scores= {"faze-9pan": [[13, 6]],
  "vp-saw": [[13, 6]],
  "navi-koi": [[13, 8]],
  "g2-itb": [[13, 3]],
  "ef-nip": [[13, 11]],
  "amka-ente": [[9, 13]],
  "betb-fnat": [[6, 13]],
  "3dma-falc": [[8, 13]],
  "faze-falc": [[13, 7]],
  "g2-ef": [[16, 13]],
  "vp-fnat": [[13, 3]],
  "navi-ente": [[13, 6]],
  "amka-nip": [[22, 20]],
  "9pan-saw": [[13, 1]],
  "betb-itb": [[13, 1]],
  "3dma-koi": [[8, 13]],
  "faze-g2": [[8, 13], [9, 13]],
  "navi-vp": [[9, 13], [9, 13]],
  "ef-falc": [[13, 5]],
  "9pan-koi": [[11, 13]],
  "ente-betb": [[10, 13]],
  "fnat-amka": [[13, 11]],
  "saw-nip": [[13, 5], [13, 3]],
  "itb-3dma": [[13, 3], [8, 13], [7, 13]],
  "fnat-koi": [[16, 19], [7, 13]],
  "faze-ef": [[19, 17], [13, 5]],
  "navi-betb": [[13, 4], [8, 13], [13, 7]],
  "falc-amka": [[13, 6], [8, 13], [13, 16]],
  "9pan-3dma": [[11, 13], [13, 7], [13, 8]],
  "ente-saw": [[2, 13], [6, 13]],
  "9pan-amka": [[13, 6], [11, 13], [9, 13]],
  "fnat-saw": [[5, 13], [4, 13]],
  "ef-betb": [[13, 7], [13, 1]],
  "vita-gl": [[13, 2]],
  "mouz-guil": [[16, 14]],
  "spir-ence": [[13, 0]],
  "mont-ecst": [[9, 13]],
  "apek-og": [[13, 5]],
  "c9-pera": [[13, 3]],
  "astr-nexu": [[13, 5]],
  "prea-hero": [[9, 13]],
  "c9-apek": [[13, 10]],
  "mouz-ecst": [[13, 8]],
  "vita-hero": [[13, 3]],
  "spir-astr": [[13, 11]],
  "guil-ence": [[6, 13]],
  "gl-pera": [[13, 6]],
  "prea-og": [[9, 13]],
  "mont-nexu": [[13, 9]],
  "vita-c9": [[8, 13], [13, 8], [5, 13]],
  "spir-mouz": [[10, 13], [11, 13]],
  "apek-mont": [[13, 5]],
  "ecst-og": [[13, 11]],
  "gl-ence": [[14, 16]],
  "astr-hero": [[7, 13]],
  "guil-nexu": [[13, 10], [8, 13], [13, 7]],
  "pera-prea": [[13, 11], [7, 13], [1, 13]],
  "apek-ecst": [[10, 13], [13, 11], [13, 11]],
  "vita-ence": [[13, 6], [13, 4]],
  "spir-hero": [[13, 8], [13, 11]],
  "mont-astr": [[8, 13], [13, 4], [8, 13]],
  "og-gl": [[8, 13], [9, 13]],
  "guil-prea": [[6, 13], [13, 7], [13, 9]],
  "ence-astr": [[13, 6], [13, 9]],
  "ecst-guil": [[3, 13], [13, 9], [13, 5]],
  "hero-gl": [[13, 5], [13, 9]],
  "furi-noun": [[13, 9]],
  "col-elev": [[13, 6]],
  "m80-oddi": [[13, 10]],
  "mibr-boss": [[9, 13]],
  "pain-lega": [[13, 2]],
  "red-wild": [[13, 1]],
  "best-liqu": [[11, 13]],
  "impe-nrg": [[13, 2]],
  "col-boss": [[13, 10]],
  "pain-red": [[13, 10]],
  "furi-liqu": [[13, 10]],
  "m80-impe": [[3, 13]],
  "mibr-nrg": [[13, 2], [16, 13]],
  "best-wild": [[9, 13], [4, 13]],
  "noun-lega": [[13, 9], [4, 13], [7, 13]],
  "elev-oddi": [[11, 13], [13, 10], [10, 13]],
  "pain-impe": [[5, 13], [4, 13]],
  "furi-col": [[13, 10], [13, 16], [13, 7]],
  "m80-wild": [[13, 5], [8, 13], [13, 3]],
  "boss-liqu": [[9, 13], [4, 13]],
  "red-oddi": [[6, 13], [13, 6], [4, 13]],
  "lega-mibr": [[13, 7], [13, 7]],
  "pain-oddi": [[13, 3], [13, 7]],
  "m80-lega": [[13, 6], [12, 16], [8, 13]],
  "col-liqu": [[9, 13], [13, 5], [13, 7]],
  "mong-mag#1": [[13, 4]],
  "atox-ghg#1": [[4, 13]],
  "mong-ghg#1": [[13, 4]],
  "tylo-jiji#1": [[13, 11]],
  "tm-lvg#1": [[7, 13]],
  "tylo-lvg#1": [[6, 13]],
  "mong-lvg#1": [[13, 8], [13, 3]],
  "mag-atox#1": [[9, 13], [3, 13]],
  "tylo-atox#1": [[13, 3], [4, 13], [2, 13]],
  "jiji-tm#1": [[16, 14], [13, 8]],
  "ghg-jiji#1": [[13, 7], [13, 7]],
  "atox-ghg#2": [[2, 13], [19, 17], [5, 13]],
  "lvg-ghg#1": [[6, 13], [13, 7], [13, 2]],
  // EU-TB
  "fnat-gl#":[[14,16],[7,13]],
  "guil-betb#":[[13,4],[13,10]],
  "9pan-astr#":[[13,11],[13,8]],
  "guil-gl#1":[[11,13],[11,13]],
  "9pan-gl#1":[[14,16],[13,8],[13,11]],
}
