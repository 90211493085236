export const Scores = {"1win-gl":[[16,19]],
  "1win-og":[[10,16],[7,16]],
  "500-ence":[[8,16],[17,19]],
  "9ine-g2":[[16,14]],
  "9ine-vita":[[16,11],[16,11]],
  "apek-bne":[[16,19]],
  "apek-gl":[[16,8]],
  "apek-spro":[[16,11],[16,11]],
  "astr-spir":[[10,16],[16,5],[16,10]],
  "auro-ef":[[19,15],[12,16],[19,16]],
  "b8-apek":[[23,25],[7,16]],
  "b8-c9#1":[[9,16],[7,16]],
  "b8-fnat":[[8,16],[16,11],[13,16]],
  "best-flux":[[14,16]],
  "best-mibr":[[12,16],[16,13],[10,16]],
  "best-noun":[[16,13]],
  "big-500":[[16,8]],
  "big-9ine":[[15,19]],
  "big-inat":[[16,12]],
  "big-nip":[[11,16],[20,22]],
  "bne-1win":[[9,16]],
  "bne-vipe":[[16,9]],
  "c9-big#1":[[16,13],[16,12]],
  "c9-ef":[[19,16]],
  "c9-ence":[[16,19],[12,16]],
  "c9-forz":[[16,5]],
  "c9-g2":[[6,16],[13,16]],
  "c9-mont":[[9,16]],
  "col-impe":[[16,13],[16,12]],
  "col-mibr":[[16,6],[16,12]],
  "col-pain":[[10,16]],
  "col-soli":[[16,10]],
  "ef-astr":[[16,7]],
  "eg-paqu":[[16,8],[13,16],[11,16]],
  "ence-forz":[[14,16]],
  "ence-inat":[[16,0],[16,14]],
  "ence-mont":[[11,16]],
  "erup-ig#1":[[16,6]],
  "erup-tm#1":[[12,16],[22,20],[10,16]],
  "falc-big#1":[[13,16],[14,16]],
  "falc-gl":[[14,16],[16,12],[8,16]],
  "falc-og":[[8,16],[13,16]],
  "faze-apek":[[16,13]],
  "faze-auro#1":[[16,13],[16,7]],
  "faze-bne":[[10,16],[14,16]],
  "faze-c9#1":[[19,15],[16,9]],
  "faze-mouz":[[8,16],[16,9],[15,19]],
  "faze-og":[[16,10]],
  "flam-noun":[[12,16],[16,5],[6,16]],
  "flux-paqu":[[16,13],[16,9]],
  "flux-yur":[[16,13]],
  "fnat-1win":[[16,12]],
  "fnat-vipe":[[22,20]],
  "forz-auro":[[16,12],[16,10]],
  "furi-eg":[[16,13]],
  "furi-los":[[16,6]],
  "g2-auro":[[16,14]],
  "g2-inat":[[16,3]],
  "ghg-erup#1":[[16,12]],
  "ghg-tylo#1":[[16,8]],
  "gl-bne#1":[[7,16],[16,14],[7,16]],
  "gl-og":[[16,9]],
  "hero-500":[[16,8]],
  "hero-auro":[[16,14]],
  "hero-mont":[[16,12],[16,14]],
  "impe-eg":[[12,16]],
  "impe-los":[[16,3],[16,0]],
  "itb-b8":[[16,13],[20,22],[16,7]],
  "key":"par23rmr","liqu-00":[[16,4]],
  "liqu-flam":[[16,6]],
  "liqu-furi":[[19,16],[13,16],[4,16]],
  "liqu-noun":[[16,11],[16,19],[16,9]],
  "mibr-00":[[16,4],[16,12]],
  "mibr-flam":[[20,22]],
  "mong-ghg#1":[[19,16],[9,16],[12,16]],
  "mong-ra#1":[[16,11]],
  "mong-ra#2":[[16,11],[16,11]],
  "mong-tm#1":[[16,10]],
  "mont-forz":[[16,11],[16,9]],
  "mont-vita#1":[[14,16],[7,16]],
  "mouz-b8":[[9,16]],
  "mouz-falc":[[8,16]],
  "mouz-saw":[[16,12]],
  "navi-falc":[[16,6]],
  "navi-faze":[[16,7],[16,11]],
  "navi-itb":[[22,19]],
  "nip-astr":[[16,4],[16,11]],
  "nip-ef":[[16,11]],
  "nip-forz":[[12,16]],
  "og-saw":[[16,11],[7,16],[16,11]],
  "pain-flux":[[16,1],[16,8]],
  "pain-furi#1":[[16,11],[8,16],[14,16]],
  "pain-paqu":[[16,2]],
  "paqu-soli":[[14,16],[16,9],[16,10]],
  "ra-tylo#1":[[16,12],[16,13]],
  "ra-vert#1":[[16,6]],
  "spir-500":[[11,16]],
  "spir-auro":[[13,16]],
  "spro-falc":[[12,16]],
  "spro-itb":[[7,16]],
  "tm-ra#1":[[14,16],[7,16]],
  "tm-vert#1":[[16,11],[8,16],[16,7]],
  "tylo-ig#1":[[16,14],[19,16]],
  "vipe-spro":[[16,7],[9,16],[6,16]],
  "vita-astr":[[16,14]],
  "vita-big":[[10,16],[16,9],[16,7]],
  "vita-g2#1":[[16,13],[16,6]],
  "vita-nip":[[16,13]],
  "vp-b8":[[14,16]],
  "vp-itb":[[13,16]],
  "vp-mouz":[[9,16],[5,16]],
  "vp-saw":[[16,9]],
  "yur-noun":[[11,16],[16,14],[12,16]]
};

