export const FinalResults = {
  "0": {
    "wins-c9": [
      [16,19],
      [7,16]
    ],
    "g2-tyl": [
      [16,7],
      [16,2],
      [12,16]
    ],
    "vici-nip": [
      [10,16],
      [16,13],
      [7,16]
    ],
    "c9-furi": [
      [16,10],
      [16,1],
      [1,16]
    ],
    "g2-fntc": [
      [16,5],
      [16,8],
      [8,16]
    ],
    "vega-nip": [
      [13,16],
      [6,16],
      [19,17]
    ],
    "ence-wins": [
      [16,13],
      [7,16],
      [16,11]
    ],
    "avg-tyl": [
      [16,14],
      [16,12]
    ],
    "vici-vita": [
      [12,16],
      [22,25]
    ],
    "nrg-tyl": [
      [16,13],
      [16,14]
    ],
    "spir-furi": [
      [14,16],
      [9,16]
    ],
    "ence-ren": [
      [14,16],
      [7,16]
    ],
    "fntc-gray": [
      [16,11],
      [16,9]
    ],
    "vici-c9": [
      [16,6]
    ],
    "wins-nip": [
      [16,6]
    ],
    "vita-vega": [
      [16,1]
    ],
    "g2-avg": [
      [10,16]
    ],
    "vici-nrg": [
      [6,16]
    ],
    "ren-nip": [
      [16,13]
    ],
    "tyl-c9": [
      [16,3]
    ],
    "ence-g2": [
      [16,6]
    ],
    "wins-fntc": [
      [16,11]
    ],
    "furi-avg": [
      [14,16]
    ],
    "gray-vita": [
      [17,19]
    ],
    "spir-vega": [
      [14,16]
    ],
    "wins-nrg": [
      [9,16]
    ],
    "furi-nip": [
      [6,16]
    ],
    "spir-ence": [
      [10,16]
    ],
    "g2-vega": [
      [16,14]
    ],
    "vita-tyl": [
      [8,16]
    ],
    "gray-c9": [
      [11,16]
    ],
    "ren-avg": [
      [16,8]
    ],
    "vici-fntc": [
      [16,9]
    ]
  },
  "1": {
    "c9-faze": [
      [9,16],
      [14,16]
    ],
    "nip-vita": [
      [16,7],
      [8,16],
      [16,7]
    ],
    "avg-ence": [
      [7,16],
      [12,16]
    ],
    "hlr-c9": [
      [12,16],
      [8,16]
    ],
    "faze-col": [
      [16,14],
      [16,11],
      [8,16]
    ],
    "g2-ence": [
      [10,16],
      [17,19]
    ],
    "nip-mibr": [
      [14,16],
      [6,16]
    ],
    "avg-navi": [
      [11,16],
      [6,16]
    ],
    "ren-vita": [
      [16,12],
      [11,16],
      [16,5]
    ],
    "nrg-col": [
      [16,19],
      [19,22]
    ],
    "liq-navi": [
      [16,9],
      [16,14]
    ],
    "astr-ren": [
      [16,2],
      [16,9],
      [17,19]
    ],
    "ence-big": [
      [16,10],
      [16,14],
      [5,16]
    ],
    "nip-hlr": [
      [16,5]
    ],
    "vita-c9": [
      [16,14]
    ],
    "faze-avg": [
      [13,16]
    ],
    "mibr-g2": [
      [16,12]
    ],
    "liq-nip": [
      [16,13]
    ],
    "astr-c9": [
      [16,0]
    ],
    "navi-vita": [
      [25,22]
    ],
    "faze-ren": [
      [14,16]
    ],
    "avg-nrg": [
      [16,13]
    ],
    "mibr-col": [
      [16,9]
    ],
    "ence-hlr": [
      [12,16]
    ],
    "big-g2": [
      [13,16]
    ],
    "c9-mibr": [
      [16,3]
    ],
    "col-astr": [
      [6,16]
    ],
    "nip-nrg": [
      [16,14]
    ],
    "avg-liq": [
      [12,16]
    ],
    "navi-g2": [
      [16,7]
    ],
    "hlr-faze": [
      [4,16]
    ],
    "big-vita": [
      [11,16]
    ],
    "ence-ren": [
      [13,16]
    ]
  },
  "2": {
    "ence-astr": [
      [4,16],
      [11,16]
    ],
    "mibr-astr": [
      [7,16],
      [14,16]
    ],
    "navi-ence": [
      [14,16],
      [16,3],
      [14,16]
    ],
    "nip-astr": [
      [14,16],
      [2,16]
    ],
    "ren-mibr": [
      [12,16],
      [6,16]
    ],
    "ence-liq": [
      [19,16],
      [16,11]
    ],
    "faze-navi": [
      [7,16],
      [13,16]
    ]
  }
}
