export const Scores = {
  "0": {
    "bne-ence": [[9,16],[13,16]],
    "bne-forz": [[7,16]],
    "cplx-out": [[9,16]],
    "cplx-vita": [[1,16]],
    "eter-bne": [[14,16]],
    "eter-mibr": [[11,16],[13,16]],
    "eter-spir": [[11,16]],
    "forz-vita": [[12,16],[9,16],[16,12]],
    "g2-ence": [[16,9],[16,19],[16,7]],
    "ihc-astr": [[2,16]],
    "ihc-cplx": [[13,16]],
    "ihc-imp": [[12,16],[10,16],[16,11]],
    "ihc-ren": [[16,12],[16,10]],
    "imp-bne": [[12,16]],
    "imp-forz": [[16,5],[16,5]],
    "liq-astr": [[16,13],[16,14]],
    "liq-cplx": [[16,12],[16,12]],
    "liq-g2": [[6,16]],
    "liq-imp": [[10,16]],
    "mibr-astr": [[13,16]],
    "mibr-bne": [[10,16],[8,16],[16,13]],
    "nine-ence": [[7,16]],
    "nine-liq": [[11,16],[11,16]],
    "nine-mibr": [[14,16]],
    "out-ence": [[6,16]],
    "out-forz": [[16,11],[16,10],[12,16]],
    "out-mibr": [[16,14]],
    "ren-eter": [[2,16]],
    "ren-forz": [[9,16]],
    "spir-astr": [[16,6],[16,12]],
    "spir-g2": [[17,19]],
    "spir-imp": [[16,6]],
    "vita-astr": [[16,8]]
  },
  "1": {
    "bne-cope": [[14,16]],
    "bne-faze": [[4,16]],
    "cope-spir": [[6,16],[8,16],[16,10]],
    "ence-cope": [[8,16]],
    "ence-faze": [[16,8]],
    "faze-c9": [[16,8]],
    "faze-cope": [[16,6],[16,13],[13,16]],
    "furi-big": [[16,10]],
    "furi-g2": [[16,11],[14,16],[16,12]],
    "furi-nip": [[10,16],[2,16]],
    "g2-hero": [[17,19]],
    "g2-navi": [[17,19]],
    "hero-ence": [[6,16],[12,16],[16,14]],
    "imp-big": [[11,16]],
    "imp-bne": [[16,14],[16,13]],
    "imp-c9": [[16,3],[9,16],[25,23]],
    "imp-cope": [[18,22],[2,16]],
    "imp-g2": [[12,16]],
    "liq-furi": [[9,16]],
    "liq-hero": [[11,16]],
    "liq-vita": [[11,16],[7,16]],
    "navi-big": [[16,8]],
    "nip-c9": [[16,14]],
    "nip-navi": [[4,16],[16,14],[6,16]],
    "out-c9": [[12,16]],
    "out-ence": [[8,16]],
    "out-g2": [[11,16],[16,10],[10,16]],
    "out-vita": [[16,8]],
    "spir-furi": [[16,12]],
    "spir-hero": [[16,12]],
    "vita-big": [[19,17],[19,22],[16,9]],
    "vita-hero": [[12,16],[16,14],[7,16]],
    "vita-nip": [[4,16]]
  },
  "2": {
    "cope-ence":[[12,16],[10,16]],
    "ence-navi":[[12,16],[7,16]],
    "faze-navi":[[16,10],[19,16]],
    "faze-nip":[[16,8],[13,16],[16,10]],
    "faze-spir":[[25,23],[16,13]],
    "hero-navi":[[8,16],[14,16],[16,10]],
    "spir-furi":[[16,8],[16,12]]
  },
  "key": "event_19"
}

export const ChallengerResults = [
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "liq",
      "spir",
      "ence"
    ],
    "buchholtz": 5,
    "code": "g2",
    "name": "G2 Esports",
    "seed": 10,
    "description": "EUB #5",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/g2.png",
    "standing": 1,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "1st",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "cplx",
      "astr",
      "forz"
    ],
    "buchholtz": -4,
    "code": "vita",
    "name": "Team Vitality",
    "seed": 13,
    "description": "EUA #6",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/vita.png",
    "standing": 2,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "2nd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "nine",
      "out",
      "g2",
      "bne"
    ],
    "buchholtz": 3,
    "code": "ence",
    "name": "ENCE",
    "seed": 9,
    "description": "EUB #4",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/ence.png",
    "standing": 3,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "3rd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "imp",
      "g2",
      "eter",
      "astr"
    ],
    "buchholtz": 1,
    "code": "spir",
    "name": "Team Spirit",
    "seed": 18,
    "description": "EUB #8",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/spir.png",
    "standing": 4,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "4th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "mibr",
      "ence",
      "cplx",
      "forz"
    ],
    "buchholtz": -2,
    "code": "out",
    "name": "Outsiders",
    "seed": 19,
    "description": "EUA #8",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/out.png",
    "standing": 5,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "5th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "spir",
      "liq",
      "bne",
      "ihc",
      "forz"
    ],
    "buchholtz": 1,
    "code": "imp",
    "name": "Imperial Esports",
    "seed": 15,
    "description": "AM #3",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/imp.png",
    "standing": 6,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "6th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "eter",
      "forz",
      "imp",
      "ence",
      "mibr"
    ],
    "buchholtz": -1,
    "code": "bne",
    "name": "Bad News Eagles",
    "seed": 16,
    "description": "EUB #7",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/bne.png",
    "standing": 7,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "7th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "g2",
      "imp",
      "nine",
      "cplx",
      "astr"
    ],
    "buchholtz": -2,
    "code": "liq",
    "name": "Team Liquid",
    "seed": 23,
    "description": "AM #5",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/liq.png",
    "standing": 8,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "8th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "ren",
      "bne",
      "vita",
      "out",
      "imp"
    ],
    "buchholtz": 4,
    "code": "forz",
    "name": "forZe",
    "seed": 11,
    "description": "EUA #5",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/forz.png",
    "standing": 9,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "9th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "ihc",
      "vita",
      "mibr",
      "spir",
      "liq"
    ],
    "buchholtz": 3,
    "code": "astr",
    "name": "Astralis",
    "seed": 12,
    "description": "EUB #6",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/astr.png",
    "standing": 10,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "10th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "out",
      "nine",
      "astr",
      "eter",
      "bne"
    ],
    "buchholtz": -3,
    "code": "mibr",
    "name": "MIBR",
    "seed": 14,
    "description": "AM #2",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/mibr.png",
    "standing": 11,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "11th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "vita",
      "ihc",
      "out",
      "liq"
    ],
    "buchholtz": 4,
    "code": "cplx",
    "name": "Complexity Gaming",
    "seed": 20,
    "description": "AM #4",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/cplx.png",
    "standing": 12,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "12th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "bne",
      "ren",
      "spir",
      "mibr"
    ],
    "buchholtz": -1,
    "code": "eter",
    "name": "Eternal Fire",
    "seed": 17,
    "description": "EUA #7",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/eter.png",
    "standing": 13,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "13th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "astr",
      "cplx",
      "ren",
      "imp"
    ],
    "buchholtz": -5,
    "code": "ihc",
    "name": "IHC Esports",
    "seed": 21,
    "description": "AP #1",
    "region": "AP",
    "logo": "https://img.majors.im/go/antwerp2022/ihc.png",
    "standing": 14,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "14th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "ence",
      "mibr",
      "liq"
    ],
    "buchholtz": 2,
    "code": "nine",
    "name": "9z Team",
    "seed": 24,
    "description": "AM #6",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/nine.png",
    "standing": 15,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "15th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "forz",
      "eter",
      "ihc"
    ],
    "buchholtz": -5,
    "code": "ren",
    "name": "Renegades",
    "seed": 22,
    "description": "AP #2",
    "region": "AP",
    "logo": "https://img.majors.im/go/antwerp2022/ren.png",
    "standing": 16,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "16th",
    "elim": true,
    "adv": false,
    "currentRound": false
  }
];

export const LegendResults = [
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "furi",
      "hero",
      "cope"
    ],
    "buchholtz": 3,
    "code": "spir",
    "name": "Team Spirit",
    "seed": 12,
    "description": "3-1, +1",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/spir.png",
    "standing": 1,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "1st",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "g2",
      "big",
      "nip"
    ],
    "buchholtz": -1,
    "code": "navi",
    "name": "Natus Vincere",
    "seed": 8,
    "description": "EUA #4",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/navi.png",
    "standing": 2,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "2nd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "faze",
      "cope",
      "out",
      "hero"
    ],
    "buchholtz": 2,
    "code": "ence",
    "name": "ENCE",
    "seed": 11,
    "description": "3-1, +3",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/ence.png",
    "standing": 3,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "3rd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "vita",
      "c9",
      "navi",
      "furi"
    ],
    "buchholtz": 1,
    "code": "nip",
    "name": "Ninjas in Pyjamas",
    "seed": 7,
    "description": "EUB #3",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/nip.png",
    "standing": 4,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "4th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "ence",
      "bne",
      "c9",
      "cope"
    ],
    "buchholtz": -2,
    "code": "faze",
    "name": "FaZe Clan",
    "seed": 6,
    "description": "EUA #3",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/faze.png",
    "standing": 5,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "5th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "bne",
      "ence",
      "spir",
      "faze",
      "imp"
    ],
    "buchholtz": 3,
    "code": "cope",
    "name": "Copenhagen Flames",
    "seed": 2,
    "description": "EUB #1",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/cope.png",
    "standing": 6,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "6th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "liq",
      "spir",
      "g2",
      "ence",
      "vita"
    ],
    "buchholtz": 0,
    "code": "hero",
    "name": "Heroic",
    "seed": 1,
    "description": "EUA #1",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/hero.png",
    "standing": 7,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "7th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "spir",
      "liq",
      "big",
      "nip",
      "g2"
    ],
    "buchholtz": -1,
    "code": "furi",
    "name": "FURIA Esports",
    "seed": 5,
    "description": "AM #1",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/furi.png",
    "standing": 8,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "8th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "navi",
      "imp",
      "hero",
      "out",
      "furi"
    ],
    "buchholtz": 2,
    "code": "g2",
    "name": "G2 Esports",
    "seed": 9,
    "description": "3-0, +5",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/g2.png",
    "standing": 9,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "9th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "nip",
      "out",
      "liq",
      "big",
      "hero"
    ],
    "buchholtz": -4,
    "code": "vita",
    "name": "Team Vitality",
    "seed": 10,
    "description": "3-0, -4",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/vita.png",
    "standing": 10,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "10th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "big",
      "g2",
      "bne",
      "c9",
      "cope"
    ],
    "buchholtz": -7,
    "code": "imp",
    "name": "Imperial Esports",
    "seed": 14,
    "description": "3-2, +1",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/imp.png",
    "standing": 11,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "11th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "imp",
      "navi",
      "furi",
      "vita"
    ],
    "buchholtz": 2,
    "code": "big",
    "name": "BIG",
    "seed": 3,
    "description": "EUA #2",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/big.png",
    "standing": 12,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "12th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "out",
      "nip",
      "faze",
      "imp"
    ],
    "buchholtz": 1,
    "code": "c9",
    "name": "Cloud9",
    "seed": 4,
    "description": "EUB #2",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/c9.png",
    "standing": 13,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "13th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "c9",
      "vita",
      "ence",
      "g2"
    ],
    "buchholtz": -2,
    "code": "out",
    "name": "Outsiders",
    "seed": 13,
    "description": "3-1, -2",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/out.png",
    "standing": 14,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "14th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "cope",
      "faze",
      "imp"
    ],
    "buchholtz": 2,
    "code": "bne",
    "name": "Bad News Eagles",
    "seed": 15,
    "description": "3-2, -1",
    "region": "EU",
    "logo": "https://img.majors.im/go/antwerp2022/bne.png",
    "standing": 15,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "15th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "hero",
      "furi",
      "vita"
    ],
    "buchholtz": 1,
    "code": "liq",
    "name": "Team Liquid",
    "seed": 16,
    "description": "3-2, -2",
    "region": "AM",
    "logo": "https://img.majors.im/go/antwerp2022/liq.png",
    "standing": 16,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "16th",
    "elim": true,
    "adv": false,
    "currentRound": false
  }
];
