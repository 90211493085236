export const Scores = {
  "0": {
    "faze-mont": [
      [
        16,
        14
      ]
    ],
    "flux-pain": [
      [
        2,
        16
      ]
    ],
    "cplx-gl": [
      [
        16,
        11
      ]
    ],
    "mngz-g2": [
      [
        11,
        16
      ]
    ],
    "liq-apex": [
      [
        12,
        16
      ]
    ],
    "gray-forz": [
      [
        16,
        14
      ]
    ],
    "mouz-nip": [
      [
        9,
        16
      ]
    ],
    "og-ence": [
      [
        6,
        16
      ]
    ],
    "pain-faze": [
      [
        12,
        16
      ]
    ],
    "g2-cplx": [
      [
        16,
        9
      ]
    ],
    "nip-ence": [
      [
        9,
        16
      ]
    ],
    "gray-apex": [
      [
        0,
        16
      ]
    ],
    "mont-flux": [
      [
        16,
        8
      ]
    ],
    "gl-mngz": [
      [
        11,
        16
      ]
    ],
    "liq-forz": [
      [
        14,
        16
      ]
    ],
    "og-mouz": [
      [
        16,
        11
      ]
    ],
    "pain-cplx": [
      [
        16,
        4
      ]
    ],
    "gray-mngz": [
      [
        16,
        10
      ]
    ],
    "nip-og": [
      [
        19,
        17
      ]
    ],
    "mont-forz": [
      [
        6,
        16
      ]
    ],
    "g2-apex": [
      [
        16,
        11
      ],
      [
        16,
        3
      ]
    ],
    "ence-faze": [
      [
        12,
        16
      ],
      [
        16,
        13
      ],
      [
        16,
        8
      ]
    ],
    "mouz-gl": [
      [
        12,
        16
      ],
      [
        5,
        16
      ]
    ],
    "liq-flux": [
      [
        11,
        16
      ],
      [
        16,
        14
      ],
      [
        16,
        13
      ]
    ],
    "og-gl": [
      [
        13,
        16
      ],
      [
        16,
        8
      ],
      [
        7,
        16
      ]
    ],
    "mngz-mont": [
      [
        13,
        16
      ],
      [
        12,
        16
      ]
    ],
    "liq-cplx": [
      [
        16,
        11
      ],
      [
        16,
        3
      ]
    ],
    "forz-faze": [
      [
        12,
        16
      ],
      [
        11,
        16
      ]
    ],
    "pain-apex": [
      [
        11,
        16
      ],
      [
        16,
        13
      ],
      [
        9,
        16
      ]
    ],
    "nip-gray": [
      [
        16,
        11
      ],
      [
        16,
        12
      ]
    ],
    "forz-gl": [
      [
        12,
        16
      ],
      [
        13,
        16
      ]
    ],
    "pain-mont": [
      [
        16,
        10
      ],
      [
        1,
        16
      ],
      [
        10,
        16
      ]
    ],
    "liq-gray": [
      [
        16,
        9
      ],
      [
        16,
        9
      ]
    ]
  },
  "1": {
    "navi-gl": [
      [
        16,
        10
      ]
    ],
    "liq-nein": [
      [
        16,
        9
      ]
    ],
    "mont-furi": [
      [
        16,
        7
      ]
    ],
    "nip-fntc": [
      [
        11,
        16
      ]
    ],
    "hero-faze": [
      [
        16,
        7
      ]
    ],
    "itb-apex": [
      [
        10,
        16
      ]
    ],
    "vita-g2": [
      [
        16,
        13
      ]
    ],
    "ence-bne": [
      [
        16,
        9
      ]
    ],
    "liq-navi": [
      [
        16,
        8
      ]
    ],
    "mont-fntc": [
      [
        19,
        17
      ]
    ],
    "vita-ence": [
      [
        16,
        13
      ]
    ],
    "hero-apex": [
      [
        16,
        10
      ]
    ],
    "nip-furi": [
      [
        16,
        8
      ]
    ],
    "gl-nein": [
      [
        16,
        13
      ]
    ],
    "g2-bne": [
      [
        13,
        16
      ]
    ],
    "itb-faze": [
      [
        16,
        12
      ]
    ],
    "nip-navi": [
      [
        9,
        16
      ]
    ],
    "gl-fntc": [
      [
        16,
        11
      ]
    ],
    "itb-ence": [
      [
        16,
        7
      ]
    ],
    "bne-apex": [
      [
        6,
        16
      ]
    ],
    "liq-hero": [
      [
        11,
        16
      ],
      [
        16,
        11
      ],
      [
        12,
        16
      ]
    ],
    "vita-mont": [
      [
        16,
        10
      ],
      [
        16,
        13
      ]
    ],
    "faze-nein": [
      [
        16,
        10
      ],
      [
        16,
        8
      ]
    ],
    "furi-g2": [
      [
        8,
        16
      ],
      [
        7,
        16
      ]
    ],
    "nip-ence": [
      [
        16,
        7
      ],
      [
        16,
        10
      ]
    ],
    "faze-bne": [
      [
        3,
        16
      ],
      [
        19,
        17
      ],
      [
        16,
        14
      ]
    ],
    "navi-mont": [
      [
        12,
        16
      ],
      [
        2,
        16
      ]
    ],
    "g2-fntc": [
      [
        19,
        16
      ],
      [
        11,
        16
      ],
      [
        13,
        16
      ]
    ],
    "liq-itb": [
      [
        19,
        17
      ],
      [
        16,
        9
      ]
    ],
    "gl-apex": [
      [
        16,
        6
      ],
      [
        16,
        9
      ]
    ],
    "navi-faze": [
      [
        16,
        13
      ],
      [
        5,
        16
      ],
      [
        19,
        22
      ]
    ],
    "itb-fntc": [
      [
        12,
        16
      ],
      [
        16,
        8
      ],
      [
        16,
        4
      ]
    ],
    "nip-apex": [
      [
        17,
        19
      ],
      [
        16,
        19
      ]
    ]
  },
  "2": {
    "hero-faze": [
      [
        16,
        14
      ],
      [
        12,
        16
      ],
      [
        16,
        6
      ]
    ],
    "vita-itb": [
      [
        16,
        11
      ],
      [
        16,
        12
      ]
    ],
    "mont-gl": [
      [
        10,
        16
      ],
      [
        3,
        16
      ]
    ],
    "liq-apex": [
      [
        10,
        16
      ],
      [
        11,
        16
      ]
    ],
    "hero-gl": [
      [
        16,
        13
      ],
      [
        14,
        16
      ],
      [
        6,
        16
      ]
    ],
    "vita-apex": [
      [
        16,
        14
      ],
      [
        16,
        12
      ]
    ],
    "vita-gl": [
      [
        16,
        6
      ],
      [
        16,
        13
      ]
    ]
  }
}

export const ChallengerResults = [
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "out",
      "evl",
      "fntc"
    ],
    "buchholtz": 1,
    "buchholtzBreakdown": [
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "evl",
        "b": -2
      },
      {
        "code": "fntc",
        "b": 1
      }
    ],
    "code": "mouz",
    "name": "MOUZ",
    "seed": 15,
    "description": "EUB #7",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/mouz_glitter.png",
    "standing": 1,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "1st",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "zzn",
      "big",
      "gl"
    ],
    "buchholtz": -2,
    "buchholtzBreakdown": [
      {
        "code": "zzn",
        "b": -3
      },
      {
        "code": "big",
        "b": 2
      },
      {
        "code": "gl",
        "b": -1
      }
    ],
    "code": "bne",
    "name": "Bad News Eagles",
    "seed": 14,
    "description": "EUA #7",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/bne_glitter.png",
    "standing": 2,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "2nd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "mouz",
      "ihc",
      "vita",
      "fntc"
    ],
    "buchholtz": 3,
    "buchholtzBreakdown": [
      {
        "code": "mouz",
        "b": 3
      },
      {
        "code": "ihc",
        "b": -2
      },
      {
        "code": "vita",
        "b": 1
      },
      {
        "code": "fntc",
        "b": 1
      }
    ],
    "code": "out",
    "name": "Outsiders",
    "seed": 18,
    "description": "EUB #8",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/out_glitter.png",
    "standing": 3,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "3rd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "furi",
      "bne",
      "gray",
      "nine"
    ],
    "buchholtz": 2,
    "buchholtzBreakdown": [
      {
        "code": "furi",
        "b": 2
      },
      {
        "code": "bne",
        "b": 3
      },
      {
        "code": "gray",
        "b": -2
      },
      {
        "code": "nine",
        "b": -1
      }
    ],
    "code": "big",
    "name": "BIG",
    "seed": 13,
    "region": "EU",
    "description": "EUB #6",
    "logo": "https://img.majors.im/go/rio2022/big_glitter.png",
    "standing": 4,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "4th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "big",
      "zzn",
      "og",
      "gl"
    ],
    "buchholtz": -3,
    "buchholtzBreakdown": [
      {
        "code": "big",
        "b": 2
      },
      {
        "code": "zzn",
        "b": -3
      },
      {
        "code": "og",
        "b": -1
      },
      {
        "code": "gl",
        "b": -1
      }
    ],
    "code": "furi",
    "name": "FURIA Esports",
    "seed": 20,
    "description": "AM #5",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/furi_glitter.png",
    "standing": 5,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "5th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "c9",
      "og",
      "mouz",
      "out",
      "nine"
    ],
    "buchholtz": 4,
    "buchholtzBreakdown": [
      {
        "code": "c9",
        "b": 1
      },
      {
        "code": "og",
        "b": -1
      },
      {
        "code": "mouz",
        "b": 3
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "nine",
        "b": -1
      }
    ],
    "code": "fntc",
    "name": "Fnatic",
    "seed": 21,
    "description": "EUA #8",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/fntc_glitter.png",
    "standing": 6,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "6th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "imp",
      "gl",
      "out",
      "gray",
      "og"
    ],
    "buchholtz": -5,
    "buchholtzBreakdown": [
      {
        "code": "imp",
        "b": -3
      },
      {
        "code": "gl",
        "b": -1
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "gray",
        "b": -2
      },
      {
        "code": "og",
        "b": -1
      }
    ],
    "code": "vita",
    "name": "Team Vitality",
    "seed": 10,
    "description": "EUB #5",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/vita_glitter.png",
    "standing": 7,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "7th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "fntc",
      "gray",
      "imp",
      "evl",
      "gl"
    ],
    "buchholtz": -7,
    "buchholtzBreakdown": [
      {
        "code": "fntc",
        "b": 1
      },
      {
        "code": "gray",
        "b": -2
      },
      {
        "code": "imp",
        "b": -3
      },
      {
        "code": "evl",
        "b": -2
      },
      {
        "code": "gl",
        "b": -1
      }
    ],
    "code": "c9",
    "name": "Cloud9",
    "seed": 12,
    "description": "EUA #5",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/c9_glitter.png",
    "standing": 8,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "8th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "nine",
      "vita",
      "bne",
      "furi",
      "c9"
    ],
    "buchholtz": 6,
    "buchholtzBreakdown": [
      {
        "code": "nine",
        "b": -1
      },
      {
        "code": "vita",
        "b": 1
      },
      {
        "code": "bne",
        "b": 3
      },
      {
        "code": "furi",
        "b": 2
      },
      {
        "code": "c9",
        "b": 1
      }
    ],
    "code": "gl",
    "name": "Team GamerLegion",
    "seed": 17,
    "description": "EUA #7",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/gl_glitter.png",
    "standing": 9,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "9th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "gray",
      "fntc",
      "furi",
      "ihc",
      "vita"
    ],
    "buchholtz": 0,
    "buchholtzBreakdown": [
      {
        "code": "gray",
        "b": -2
      },
      {
        "code": "fntc",
        "b": 1
      },
      {
        "code": "furi",
        "b": 2
      },
      {
        "code": "ihc",
        "b": -2
      },
      {
        "code": "vita",
        "b": 1
      }
    ],
    "code": "og",
    "name": "OG",
    "seed": 9,
    "description": "EUB #4",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/og_glitter.png",
    "standing": 10,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "10th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "gl",
      "imp",
      "evl",
      "big",
      "fntc"
    ],
    "buchholtz": -3,
    "buchholtzBreakdown": [
      {
        "code": "gl",
        "b": -1
      },
      {
        "code": "imp",
        "b": -3
      },
      {
        "code": "evl",
        "b": -2
      },
      {
        "code": "big",
        "b": 2
      },
      {
        "code": "fntc",
        "b": 1
      }
    ],
    "code": "nine",
    "name": "9z Team",
    "seed": 16,
    "description": "AM #3",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/nine_glitter.png",
    "standing": 11,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "11th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "og",
      "c9",
      "big",
      "vita"
    ],
    "buchholtz": 3,
    "buchholtzBreakdown": [
      {
        "code": "og",
        "b": -1
      },
      {
        "code": "c9",
        "b": 1
      },
      {
        "code": "big",
        "b": 2
      },
      {
        "code": "vita",
        "b": 1
      }
    ],
    "code": "gray",
    "name": "Grayhound Gaming",
    "seed": 24,
    "description": "AP #2",
    "region": "AP",
    "logo": "https://img.majors.im/go/rio2022/gray_glitter.png",
    "standing": 12,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "12th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "ihc",
      "mouz",
      "nine",
      "c9"
    ],
    "buchholtz": 1,
    "buchholtzBreakdown": [
      {
        "code": "ihc",
        "b": -2
      },
      {
        "code": "mouz",
        "b": 3
      },
      {
        "code": "nine",
        "b": -1
      },
      {
        "code": "c9",
        "b": 1
      }
    ],
    "code": "evl",
    "name": "Evil Geniuses",
    "seed": 11,
    "description": "AM #2",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/evl_glitter.png",
    "standing": 13,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "13th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "evl",
      "out",
      "zzn",
      "og"
    ],
    "buchholtz": -4,
    "buchholtzBreakdown": [
      {
        "code": "evl",
        "b": -2
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "zzn",
        "b": -3
      },
      {
        "code": "og",
        "b": -1
      }
    ],
    "code": "ihc",
    "name": "IHC Esports",
    "seed": 22,
    "description": "AP #1",
    "region": "AP",
    "logo": "https://img.majors.im/go/rio2022/ihc_glitter.png",
    "standing": 14,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "14th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "bne",
      "furi",
      "ihc"
    ],
    "buchholtz": 3,
    "buchholtzBreakdown": [
      {
        "code": "bne",
        "b": 3
      },
      {
        "code": "furi",
        "b": 2
      },
      {
        "code": "ihc",
        "b": -2
      }
    ],
    "code": "zzn",
    "name": "00 Nation",
    "seed": 19,
    "description": "AM #4",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/zzn_glitter.png",
    "standing": 15,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "15th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "vita",
      "nine",
      "c9"
    ],
    "buchholtz": 1,
    "buchholtzBreakdown": [
      {
        "code": "vita",
        "b": 1
      },
      {
        "code": "nine",
        "b": -1
      },
      {
        "code": "c9",
        "b": 1
      }
    ],
    "code": "imp",
    "name": "Imperial Esports",
    "seed": 23,
    "description": "AM #6",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/imp_glitter.png",
    "standing": 16,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "16th",
    "elim": true,
    "adv": false,
    "currentRound": false
  }
];

export const LegendResults = [
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "faze",
      "navi",
      "hero"
    ],
    "buchholtz": 0,
    "buchholtzBreakdown": [
      {
        "code": "faze",
        "b": -3
      },
      {
        "code": "navi",
        "b": 1
      },
      {
        "code": "hero",
        "b": 2
      }
    ],
    "code": "c9",
    "name": "Cloud9",
    "seed": 16,
    "description": "3-2, -7",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/c9_glitter.png",
    "standing": 1,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "1st",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 0,
    "w": 3,
    "opponents": [
      "ence",
      "spir",
      "big"
    ],
    "buchholtz": -1,
    "buchholtzBreakdown": [
      {
        "code": "ence",
        "b": -1
      },
      {
        "code": "spir",
        "b": 1
      },
      {
        "code": "big",
        "b": -1
      }
    ],
    "code": "furi",
    "name": "FURIA Esports",
    "seed": 13,
    "description": "3-1, -3",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/furi_glitter.png",
    "standing": 2,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "2nd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "out",
      "fntc",
      "c9",
      "liq"
    ],
    "buchholtz": 6,
    "buchholtzBreakdown": [
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "fntc",
        "b": 2
      },
      {
        "code": "c9",
        "b": 3
      },
      {
        "code": "liq",
        "b": -1
      }
    ],
    "code": "hero",
    "name": "Heroic",
    "seed": 6,
    "description": "EUB #3",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/hero_glitter.png",
    "standing": 3,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "3rd",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "hero",
      "nip",
      "spir",
      "mouz"
    ],
    "buchholtz": 1,
    "buchholtzBreakdown": [
      {
        "code": "hero",
        "b": 2
      },
      {
        "code": "nip",
        "b": -3
      },
      {
        "code": "spir",
        "b": 1
      },
      {
        "code": "mouz",
        "b": 1
      }
    ],
    "code": "out",
    "name": "Outsiders",
    "seed": 11,
    "description": "3-1, +3",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/out_glitter.png",
    "standing": 4,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "4th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 1,
    "w": 3,
    "opponents": [
      "nip",
      "hero",
      "ence",
      "big"
    ],
    "buchholtz": -3,
    "buchholtzBreakdown": [
      {
        "code": "nip",
        "b": -3
      },
      {
        "code": "hero",
        "b": 2
      },
      {
        "code": "ence",
        "b": -1
      },
      {
        "code": "big",
        "b": -1
      }
    ],
    "code": "fntc",
    "name": "Fnatic",
    "seed": 14,
    "description": "3-2, +4",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/fntc_glitter.png",
    "standing": 5,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "5th",
    "elim": false,
    "adv": true,
    "currentRound": false
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "bne",
      "furi",
      "out",
      "spr",
      "liq"
    ],
    "buchholtz": 0,
    "buchholtzBreakdown": [
      {
        "code": "bne",
        "b": -2
      },
      {
        "code": "furi",
        "b": 3
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "spr",
        "b": -2
      },
      {
        "code": "liq",
        "b": -1
      }
    ],
    "code": "spir",
    "name": "Team Spirit",
    "seed": 7,
    "description": "EUA #4",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/spir_glitter.png",
    "standing": 6,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "6th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "vita",
      "c9",
      "liq",
      "bne",
      "big"
    ],
    "buchholtz": -3,
    "buchholtzBreakdown": [
      {
        "code": "vita",
        "b": -2
      },
      {
        "code": "c9",
        "b": 3
      },
      {
        "code": "liq",
        "b": -1
      },
      {
        "code": "bne",
        "b": -2
      },
      {
        "code": "big",
        "b": -1
      }
    ],
    "code": "navi",
    "name": "Natus Vincere",
    "seed": 2,
    "description": "EUB #1",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/navi_glitter.png",
    "standing": 7,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "7th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 2,
    "w": 3,
    "opponents": [
      "liq",
      "big",
      "vita",
      "out",
      "ence"
    ],
    "buchholtz": -3,
    "buchholtzBreakdown": [
      {
        "code": "liq",
        "b": -1
      },
      {
        "code": "big",
        "b": -1
      },
      {
        "code": "vita",
        "b": -2
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "ence",
        "b": -1
      }
    ],
    "code": "mouz",
    "name": "MOUZ",
    "seed": 9,
    "description": "3-0, +1",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/mouz_glitter.png",
    "standing": 8,
    "status": "advanced",
    "until": 8,
    "abbrev": "A",
    "ordinalStanding": "8th",
    "elim": false,
    "adv": true,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "spr",
      "mouz",
      "furi",
      "fntc",
      "navi"
    ],
    "buchholtz": 5,
    "buchholtzBreakdown": [
      {
        "code": "spr",
        "b": -2
      },
      {
        "code": "mouz",
        "b": 1
      },
      {
        "code": "furi",
        "b": 3
      },
      {
        "code": "fntc",
        "b": 2
      },
      {
        "code": "navi",
        "b": 1
      }
    ],
    "code": "big",
    "name": "BIG",
    "seed": 12,
    "description": "3-1, +2",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/big_glitter.png",
    "standing": 9,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "9th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "mouz",
      "spr",
      "navi",
      "hero",
      "spir"
    ],
    "buchholtz": 3,
    "buchholtzBreakdown": [
      {
        "code": "mouz",
        "b": 1
      },
      {
        "code": "spr",
        "b": -2
      },
      {
        "code": "navi",
        "b": 1
      },
      {
        "code": "hero",
        "b": 2
      },
      {
        "code": "spir",
        "b": 1
      }
    ],
    "code": "liq",
    "name": "Team Liquid",
    "seed": 8,
    "description": "AM #1",
    "region": "AM",
    "logo": "https://img.majors.im/go/rio2022/liq_glitter.png",
    "standing": 10,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "10th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 2,
    "opponents": [
      "furi",
      "bne",
      "fntc",
      "vita",
      "mouz"
    ],
    "buchholtz": 2,
    "buchholtzBreakdown": [
      {
        "code": "furi",
        "b": 3
      },
      {
        "code": "bne",
        "b": -2
      },
      {
        "code": "fntc",
        "b": 2
      },
      {
        "code": "vita",
        "b": -2
      },
      {
        "code": "mouz",
        "b": 1
      }
    ],
    "code": "ence",
    "name": "ENCE",
    "seed": 4,
    "description": "EUB #2",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/ence_glitter.png",
    "standing": 11,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "11th",
    "elim": true,
    "adv": false,
    "currentRound": true
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "spir",
      "ence",
      "faze",
      "navi"
    ],
    "buchholtz": -2,
    "buchholtzBreakdown": [
      {
        "code": "spir",
        "b": 1
      },
      {
        "code": "ence",
        "b": -1
      },
      {
        "code": "faze",
        "b": -3
      },
      {
        "code": "navi",
        "b": 1
      }
    ],
    "code": "bne",
    "name": "Bad News Eagles",
    "seed": 10,
    "description": "3-0, -2",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/bne_glitter.png",
    "standing": 12,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "12th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "navi",
      "faze",
      "mouz",
      "ence"
    ],
    "buchholtz": -2,
    "buchholtzBreakdown": [
      {
        "code": "navi",
        "b": 1
      },
      {
        "code": "faze",
        "b": -3
      },
      {
        "code": "mouz",
        "b": 1
      },
      {
        "code": "ence",
        "b": -1
      }
    ],
    "code": "vita",
    "name": "Team Vitality",
    "seed": 15,
    "description": "3-2, -5",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/vita_glitter.png",
    "standing": 13,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "13th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 1,
    "opponents": [
      "big",
      "liq",
      "nip",
      "spir"
    ],
    "buchholtz": -4,
    "buchholtzBreakdown": [
      {
        "code": "big",
        "b": -1
      },
      {
        "code": "liq",
        "b": -1
      },
      {
        "code": "nip",
        "b": -3
      },
      {
        "code": "spir",
        "b": 1
      }
    ],
    "code": "spr",
    "name": "Sprout",
    "seed": 5,
    "description": "EUA #3",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/spr_glitter.png",
    "standing": 14,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "14th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "fntc",
      "out",
      "spr"
    ],
    "buchholtz": 2,
    "buchholtzBreakdown": [
      {
        "code": "fntc",
        "b": 2
      },
      {
        "code": "out",
        "b": 2
      },
      {
        "code": "spr",
        "b": -2
      }
    ],
    "code": "nip",
    "name": "Ninjas in Pyjamas",
    "seed": 3,
    "description": "EUA #2",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/nip_glitter.png",
    "standing": 15,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "15th",
    "elim": true,
    "adv": false,
    "currentRound": false
  },
  {
    "l": 3,
    "w": 0,
    "opponents": [
      "c9",
      "vita",
      "bne"
    ],
    "buchholtz": -1,
    "buchholtzBreakdown": [
      {
        "code": "c9",
        "b": 3
      },
      {
        "code": "vita",
        "b": -2
      },
      {
        "code": "bne",
        "b": -2
      }
    ],
    "code": "faze",
    "name": "FaZe Clan",
    "seed": 1,
    "description": "EUA #1",
    "region": "EU",
    "logo": "https://img.majors.im/go/rio2022/faze_glitter.png",
    "standing": 16,
    "status": "eliminated",
    "until": 16,
    "abbrev": "E",
    "ordinalStanding": "16th",
    "elim": true,
    "adv": false,
    "currentRound": false
  }
];
