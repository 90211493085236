export const Scores = {
  "am/00-am/9z": [[16,19]],
  "am/9z-am/atk": [[16,2],[16,7]],
  "am/9z-am/eg": [[5,16],[16,11],[13,16]],
  "am/9z-am/impe": [[22,20]],
  "am/arct-am/atk": [[11,16]],
  "am/arct-am/isur": [[9,16],[10,16]],
  "am/col-am/00": [[13,16],[16,10],[14,16]],
  "am/col-am/atk": [[16,5]],
  "am/col-am/isur": [[11,16],[16,7],[16,1]],
  "am/col-am/liqu": [[9,16],[16,14],[7,16]],
  "am/col-am/pain": [[16,7]],
  "am/eg-am/liqu#1": [[23,25],[17,19]],
  "am/furi-am/eg": [[14,16]],
  "am/furi-am/infi": [[16,2]],
  "am/furi-am/pain": [[16,8],[16,13]],
  "am/furi-am/plan": [[16,2]],
  "am/impe-am/00": [[11,16]],
  "am/impe-am/isur": [[16,2]],
  "am/impe-am/noun": [[22,18],[16,10]],
  "am/impe-am/one": [[16,12],[16,19],[16,11]],
  "am/isur-am/00": [[10,16]],
  "am/liqu-am/mibr": [[19,16]],
  "am/liqu-am/one": [[16,6]],
  "am/mibr-am/atk": [[8,16]],
  "am/mibr-am/isur": [[16,13],[14,16],[12,16]],
  "am/mibr-am/plan": [[16,9]],
  "am/noun-am/eg": [[7,16]],
  "am/noun-am/infi": [[16,2]],
  "am/noun-am/plan": [[16,19],[22,20],[16,12]],
  "am/one-am/infi": [[16,14],[16,10]],
  "am/pain-am/arct": [[16,13]],
  "am/pain-am/atk": [[16,9],[16,6]],
  "am/pain-am/noun": [[16,8]],
  "am/plan-am/one": [[16,10]],
  "ap/ghg-ap/ra#1": [[16,9],[2,16],[19,17]],
  "ap/ghg-ap/jjh#1": [[16, 11], [16, 12]],
  "ap/ihc-ap/ghg#1": [[16, 12], [19, 16]],
  "ap/ihc-ap/jjh#1": [[16,14],[16,4]],
  "ap/jjh-ap/ra#1": [[16, 4], [11, 16], [16, 10]],
  "eu/1win-eu/fnat": [[5,16],[9,16]],
  "eu/astr-eu/forz": [[14,16],[14,16]],
  "eu/auro-eu/ef": [[9,16],[19,15],[8,16]],
  "eu/auro-eu/fnat": [[4,16]],
  "eu/auro-eu/gl": [[10,16]],
  "eu/b8-eu/ecst": [[19,16]],
  "eu/b8-eu/gl": [[12,16],[10,16]],
  "eu/big-eu/ence": [[9,16],[14,16]],
  "eu/big-eu/hero": [[11,16],[12,16]],
  "eu/big-eu/k23": [[20,22],[16,11],[19,15]],
  "eu/big-eu/sang": [[16,3]],
  "eu/bne-eu/astr": [[16,3]],
  "eu/bne-eu/ef": [[16,14]],
  "eu/c9-eu/1win": [[16,12],[16,13]],
  "eu/c9-eu/b8": [[16,6]],
  "eu/ef-eu/astr": [[9,16]],
  "eu/ence-eu/falc": [[16,6]],
  "eu/ence-eu/heet": [[16,8]],
  "eu/falc-eu/heet": [[16,9],[13,16],[19,17]],
  "eu/falc-eu/k23": [[16,9]],
  "eu/falc-eu/og": [[12,16]],
  "eu/faze-eu/fnat": [[19,17]],
  "eu/faze-eu/gl": [[28,25]],
  "eu/faze-eu/spro": [[19,17],[16,19],[16,5]],
  "eu/fnat-eu/b8": [[9,16]],
  "eu/fnat-eu/ef": [[16,10],[16,11]],
  "eu/forz-eu/1win": [[14,16]],
  "eu/forz-eu/bne": [[16,13],[12,16],[4,16]],
  "eu/forz-eu/ecst": [[16,7],[16,7]],
  "eu/g2-eu/1win": [[13,16]],
  "eu/g2-eu/c9": [[13,16]],
  "eu/g2-eu/ecst": [[16,0]],
  "eu/gl-eu/g2": [[3,16],[16,4],[16,12]],
  "eu/heet-eu/saw": [[19,16],[9,16],[16,7]],
  "eu/hero-eu/outs": [[12,16]],
  "eu/hero-eu/sang": [[16,6]],
  "eu/hero-eu/saw": [[16,5]],
  "eu/ig-eu/fant": [[13,16],[22,20],[16,13]],
  "eu/ig-eu/sang": [[10,16],[6,16]],
  "eu/k23-eu/heet": [[16,13]],
  "eu/k23-eu/mont": [[19,17],[16,11]],
  "eu/mont-eu/saw": [[16,3]],
  "eu/mouz-eu/k23": [[16,10]],
  "eu/mouz-eu/og": [[16,10]],
  "eu/mouz-eu/sang": [[19,16],[9,16],[16,12]],
  "eu/mouz-eu/vita": [[16,13],[8,16],[7,16]],
  "eu/navi-eu/ig": [[16,5]],
  "eu/navi-eu/mouz": [[16,11]],
  "eu/nip-eu/astr": [[16,12]],
  "eu/nip-eu/bne": [[16,7]],
  "eu/nip-eu/c9": [[16,9],[11,16],[16,14]],
  "eu/og-eu/hero#1": [[11,16],[16,14],[13,16]],
  "eu/og-eu/ig": [[16,5]],
  "eu/outs-eu/falc": [[16,7],[16,5]],
  "eu/outs-eu/mont": [[16,9]],
  "eu/outs-eu/navi": [[13,16],[4,16]],
  "eu/outs-eu/og": [[16,13],[5,16],[13,16]],
  "eu/sang-eu/fant": [[16,12]],
  "eu/spir-eu/1win": [[16,9]],
  "eu/spir-eu/bne": [[16,6],[16,11]],
  "eu/spir-eu/c9#1": [[16,8],[5,16],[16,13]],
  "eu/spir-eu/gl": [[22,20]],
  "eu/spir-eu/spro": [[12,16]],
  "eu/spro-eu/b8": [[13,16],[16,13],[16,7]],
  "eu/spro-eu/forz": [[19,17]],
  "eu/vita-eu/big": [[10,16]],
  "eu/vita-eu/fant": [[16,6]],
  "eu/vita-eu/hero#1": [[5,16],[22,19],[5,16]],
  "eu/vita-eu/mont": [[16,7]],
  "am/impe-am/pain#1": [[16,14],[19,16]],
  "am/impe-am/col#1": [[16,13],[11,16],[22,20]],
  "key": "rio22rmr"
}
