export const Scores = {
  "0": {
    "ecst-c9": [
      [
        10,
        13
      ]
    ],
      "eter-mngz": [
      [
        13,
        6
      ]
    ],
      "pain-apex": [
      [
        13,
        8
      ]
    ],
      "ence-imp": [
      [
        19,
        22
      ]
    ],
      "hero-lynn": [
      [
        13,
        5
      ]
    ],
      "saw-koi": [
      [
        13,
        4
      ]
    ],
      "gl-amka": [
      [
        13,
        8
      ]
    ],
      "lgcy-furi": [
      [
        16,
        13
      ]
    ],
      "mngz-ecst": [
      [
        10,
        13
      ]
    ],
      "pain-eter": [
      [
        2,
        13
      ]
    ],
      "apex-amka": [
      [
        16,
        13
      ]
    ],
      "hero-imp": [
      [
        16,
        14
      ]
    ],
      "lgcy-c9": [
      [
        10,
        13
      ]
    ],
      "koi-ence": [
      [
        12,
        16
      ]
    ],
      "gl-saw": [
      [
        9,
        13
      ]
    ],
      "lynn-furi": [
      [
        13,
        9
      ]
    ],
      "pain-ence": [
      [
        13,
        3
      ]
    ],
      "imp-apex": [
      [
        13,
        7
      ]
    ],
      "lynn-ecst": [
      [
        8,
        13
      ]
    ],
      "lgcy-gl": [
      [
        4,
        13
      ]
    ],
      "mngz-amka": [
      [
        13,
        2
      ],
      [
        8,
        13
      ],
      [
        13,
        10
      ]
    ],
      "hero-eter": [
      [
        16,
        14
      ],
      [
        13,
        7
      ]
    ],
      "c9-saw": [
      [
        19,
        17
      ],
      [
        13,
        9
      ]
    ],
      "koi-furi": [
      [
        6,
        13
      ],
      [
        9,
        13
      ]
    ],
      "mngz-lynn": [
      [
        13,
        8
      ],
      [
        13,
        6
      ]
    ],
      "gl-eter": [
      [
        13,
        4
      ],
      [
        2,
        13
      ],
      [
        11,
        13
      ]
    ],
      "lgcy-apex": [
      [
        13,
        7
      ],
      [
        13,
        7
      ]
    ],
      "saw-pain": [
      [
        10,
        13
      ],
      [
        7,
        13
      ]
    ],
      "furi-ence": [
      [
        13,
        8
      ],
      [
        13,
        9
      ]
    ],
      "imp-ecst": [
      [
        4,
        13
      ],
      [
        13,
        10
      ],
      [
        9,
        13
      ]
    ],
      "mngz-lgcy": [
      [
        13,
        9
      ],
      [
        13,
        6
      ]
    ],
      "imp-gl": [
      [
        12,
        16
      ],
      [
        13,
        6
      ],
      [
        13,
        5
      ]
    ],
      "saw-furi": [
      [
        7,
        13
      ],
      [
        7,
        13
      ]
    ]
  },
  "1": {
    "cplx-pain": [
      [
        13,
        9
      ]
    ],
      "mouz-ecst": [
      [
        13,
        11
      ]
    ],
      "mngz-navi": [
      [
        10,
        13
      ]
    ],
      "vp-imp": [
      [
        6,
        13
      ]
    ],
      "vita-eter": [
      [
        10,
        13
      ]
    ],
      "hero-faze": [
      [
        13,
        10
      ]
    ],
      "furi-g2": [
      [
        3,
        13
      ]
    ],
      "spir-c9": [
      [
        13,
        7
      ]
    ],
      "mouz-eter": [
      [
        13,
        11
      ]
    ],
      "vita-mngz": [
      [
        13,
        9
      ]
    ],
      "vp-pain": [
      [
        13,
        9
      ]
    ],
      "hero-cplx": [
      [
        17,
        19
      ]
    ],
      "ecst-c9": [
      [
        3,
        13
      ]
    ],
      "navi-g2": [
      [
        13,
        11
      ]
    ],
      "furi-faze": [
      [
        7,
        13
      ]
    ],
      "spir-imp": [
      [
        13,
        2
      ]
    ],
      "c9-g2": [
      [
        13,
        9
      ]
    ],
      "vp-hero": [
      [
        13,
        6
      ]
    ],
      "vita-imp": [
      [
        13,
        9
      ]
    ],
      "faze-eter": [
      [
        1,
        13
      ]
    ],
      "mouz-cplx": [
      [
        13,
        1
      ],
      [
        13,
        2
      ]
    ],
      "mngz-pain": [
      [
        13,
        9
      ],
      [
        10,
        13
      ],
      [
        19,
        22
      ]
    ],
      "navi-spir": [
      [
        16,
        13
      ],
      [
        9,
        13
      ],
      [
        7,
        13
      ]
    ],
      "furi-ecst": [
      [
        7,
        13
      ],
      [
        16,
        14
      ],
      [
        7,
        13
      ]
    ],
      "imp-faze": [
      [
        8,
        13
      ],
      [
        13,
        8
      ],
      [
        8,
        13
      ]
    ],
      "vp-eter": [
      [
        13,
        6
      ],
      [
        6,
        13
      ],
      [
        7,
        13
      ]
    ],
      "g2-ecst": [
      [
        13,
        7
      ],
      [
        11,
        13
      ],
      [
        13,
        8
      ]
    ],
      "vita-cplx": [
      [
        19,
        16
      ],
      [
        7,
        13
      ],
      [
        13,
        10
      ]
    ],
      "pain-hero": [
      [
        13,
        6
      ],
      [
        13,
        9
      ]
    ],
      "navi-c9": [
      [
        16,
        14
      ],
      [
        6,
        13
      ],
      [
        2,
        13
      ]
    ],
      "faze-cplx": [
      [
        13,
        6
      ],
      [
        13,
        6
      ]
    ],
      "vp-g2": [
      [
        16,
        14
      ],
      [
        11,
        13
      ],
      [
        6,
        13
      ]
    ],
      "pain-navi": [
      [
        10,
        13
      ],
      [
        9,
        13
      ]
    ]
  },
  "2": {}
}
