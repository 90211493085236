export const Scores = {"vp-sinn": [[13, 9]],
  "navi-ecst": [[13, 6]],
  "c9-og": [[13, 5]],
  "big-perm": [[16, 13]],
  "betb-z10": [[13, 6]],
  "3dma-jano": [[13, 7]],
  "9pan-nexu": [[13, 4]],
  "saw-fnat": [[16, 13]],
  "vp-saw": [[13, 8]],
  "navi-9pan": [[13, 6]],
  "c9-3dma": [[13, 10]],
  "big-betb": [[9, 13]],
  "sinn-fnat": [[7, 13]],
  "ecst-nexu": [[13, 11]],
  "og-jano": [[13, 10]],
  "perm-z10": [[4, 13]],
  "navi-vp": [[19, 16], [16, 12]],
  "c9-betb": [[13, 3], [13, 5]],
  "saw-z10": [[13, 11]],
  "big-fnat": [[8, 13]],
  "3dma-og": [[6, 13]],
  "9pan-ecst": [[13, 11]],
  "sinn-nexu": [[11, 13], [11, 13]],
  "perm-jano": [[13, 3], [13, 11]],
  "vp-fnat": [[13, 10], [13, 9]],
  "betb-og": [[13, 9], [13, 9]],
  "9pan-saw": [[9, 13], [1, 13]],
  "ecst-perm": [[13, 8], [13, 5]],
  "big-nexu": [[5, 13], [14, 16]],
  "3dma-z10": [[13, 10], [13, 7]],
  "9pan-3dma": [[13, 11], [14, 16], [13, 10]],
  "og-nexu": [[13, 6], [13, 4]],
  "ecst-fnat": [[8, 13], [10, 13]],
  "mouz-eye": [[13, 8]],
  "spir-koi": [[9, 13]],
  "ef-pera": [[13, 4]],
  "amka-sync": [[13, 3]],
  "astr-ex-t": [[13, 7]],
  "prea-entr": [[13, 11]],
  "auro-ikla": [[10, 13]],
  "forz-hero": [[9, 13]],
  "mouz-hero": [[16, 12]],
  "ef-ikla": [[13, 10]],
  "amka-koi": [[13, 10]],
  "astr-prea": [[13, 6]],
  "spir-entr": [[16, 14]],
  "auro-ex-t": [[9, 13]],
  "forz-sync": [[6, 13]],
  "eye-pera": [[7, 13]],
  "ef-mouz": [[16, 14], [10, 13], [13, 8]],
  "amka-astr": [[25, 21], [9, 13], [13, 10]],
  "koi-hero": [[16, 14]],
  "prea-spir": [[14, 16]],
  "pera-ikla": [[13, 4]],
  "sync-ex-t": [[11, 13]],
  "eye-entr": [[2, 13], [8, 13]],
  "auro-forz": [[9, 13], [8, 13]],
  "astr-pera": [[13, 6], [13, 10]],
  "koi-ex-t": [[16, 13], [8, 13], [11, 13]],
  "mouz-spir": [[2, 13], [6, 13]],
  "sync-entr": [[13, 11], [13, 8]],
  "prea-forz": [[14, 16], [13, 11], [13, 11]],
  "ikla-hero": [[4, 13], [3, 13]],
  "koi-pera": [[13, 6], [13, 7]],
  "mouz-prea": [[13, 2], [8, 13], [13, 9]],
  "sync-hero": [[4, 13], [9, 13]],
  "col-fa": [[13, 1]],
  "m80-rock": [[13, 1]],
  "noun-liqu": [[10, 13]],
  "bada-nrg": [[14, 16]],
  "carp-los": [[13, 9]],
  "part-mlbl": [[16, 13]],
  "wild-vagr": [[13, 11]],
  "boss-clou": [[13, 11]],
  "col-nrg": [[25, 21]],
  "m80-liqu": [[13, 3]],
  "boss-wild": [[8, 13]],
  "part-carp": [[16, 12]],
  "noun-vagr": [[13, 11]],
  "bada-los": [[13, 5]],
  "fa-mlbl": [[6, 13]],
  "rock-clou": [[13, 4]],
  "m80-wild": [[10, 13], [13, 11], [17, 19]],
  "part-col": [[0, 13], [6, 13]],
  "liqu-bada": [[13, 11]],
  "nrg-noun": [[8, 13]],
  "boss-mlbl": [[13, 8]],
  "carp-rock": [[5, 13]],
  "fa-los": [[7, 13], [13, 7], [3, 13]],
  "vagr-clou": [[9, 13], [11, 13]],
  "m80-noun": [[13, 10], [3, 13], [13, 5]],
  "boss-rock": [[13, 7], [13, 6]],
  "part-liqu": [[10, 13], [6, 13]],
  "nrg-los": [[13, 1], [13, 6]],
  "carp-mlbl": [[13, 1], [13, 5]],
  "bada-clou": [[13, 6], [16, 13]],
  "part-bada": [[9, 13], [13, 10], [8, 13]],
  "rock-nrg": [[16, 14], [16, 13]],
  "noun-carp": [[13, 6], [13, 5]],
  "mibr-oddi": [[19, 17]],
  "9z-lega": [[11, 13]],
  "pain-w7m": [[13, 10]],
  "red-case": [[13, 9]],
  "impe-galo": [[13, 6]],
  "flux-aya": [[13, 7]],
  "best-flam": [[13, 3]],
  "shar-tima": [[11, 13]],
  "mibr-tima": [[13, 10]],
  "pain-lega": [[3, 13]],
  "red-flux": [[13, 7]],
  "best-impe": [[13, 11]],
  "9z-aya": [[25, 22]],
  "shar-galo": [[13, 3]],
  "oddi-flam": [[13, 8]],
  "w7m-case": [[16, 14]],
  "mibr-best": [[19, 15], [13, 11]],
  "lega-red": [[8, 13], [13, 11], [13, 6]],
  "pain-shar": [[13, 6]],
  "tima-w7m": [[8, 13]],
  "9z-oddi": [[7, 13]],
  "impe-flux": [[13, 9]],
  "case-aya": [[13, 11], [2, 13], [13, 9]],
  "flam-galo": [[13, 10], [13, 10]],
  "pain-impe": [[11, 13], [13, 16]],
  "best-w7m": [[11, 13], [13, 8], [13, 4]],
  "red-oddi": [[11, 13], [13, 8], [10, 13]],
  "tima-flam": [[11, 13], [11, 13]],
  "9z-shar": [[16, 12], [19, 15]],
  "flux-case": [[19, 16], [11, 13], [5, 13]],
  "pain-case": [[19, 17], [13, 7]],
  "red-flam": [[13, 5], [8, 13], [13, 5]],
  "9z-w7m": [[13, 11], [13, 7]],

  // SA
  "9z-red#1": [[16,19], [16,19]],

  // EU-D
  "ecst-pera#": [[13,3],[13,11]],
  "sync-nexu#": [[10,13],[4,13]],
  "3dma-prea#": [[11,13],[13,5],[7,13]],
  "sync-pera#1": [[9,13],[6,13]],
  "sync-3dma#1": [[8,13],[4,13]],
}
