export const Scores = {
  "am/00-am/9z": [[11,16],[16,14],[10,16]],
  "am/00-am/eg": [[9,16]],
  "am/9z-am/bnb": [[16,11],[16,13]],
  "am/caet-am/9z": [[13,16],[9,16]],
  "am/caet-am/bnb": [[16,12]],
  "am/case-am/bnb": [[16,10]],
  "am/case-am/impe": [[6,16]],
  "am/case-am/part": [[6,16]],
  "am/col-am/00": [[16,5]],
  "am/col-am/caet": [[16,5],[16,5]],
  "am/col-am/one": [[16,8]],
  "am/eg-am/9z": [[16,5]],
  "am/eg-am/pain": [[12,16]],
  "am/eg-am/part": [[16,12],[14,16],[16,11]],
  "am/furi-am/col": [[16,7],[16,11]],
  "am/furi-am/isur": [[16,4]],
  "am/furi-am/levi": [[16,4]],
  "am/impe-am/caet": [[16,12]],
  "am/impe-am/mibr": [[16,12],[8,16],[10,16]],
  "am/impe-am/part": [[9,16],[16,11],[16,12]],
  "am/isur-am/caet": [[13,16]],
  "am/isur-am/case": [[8,16],[9,16]],
  "am/liqu-am/00": [[8,16]],
  "am/liqu-am/eg": [[16,9],[16,10]],
  "am/liqu-am/one": [[16,3]],
  "am/mibr-am/9z": [[16,13]],
  "am/mibr-am/pain": [[25,23]],
  "am/one-am/levi": [[16,13],[19,17]],
  "am/pain-am/case": [[16,14],[16,6]],
  "am/pain-am/liqu": [[1,16]],
  "am/pain-am/one": [[16,13],[16,10]],
  "am/part-am/isur": [[8,16]],
  "am/part-am/levi": [[16,11]],
  "ap/ihc-ap/rng#1": [[16,10],[16,4]],
  "ap/l4or-ap/rng#1": [[13,16],[8,16]],
  "ap/rng-ap/tylo#1": [[16,5],[22,20]],
  "ap/tylo-ap/ihc#1": [[13,16],[16,19]],
  "ap/tylo-ap/l4or#1": [[16,14],[16,8]],
  "am/eg-am/9z#1": [[11,16],[16,12],[14,16]],
  "am/mibr-am/furi#1": [[9,16],[4,16]],
  "am/pain-am/9z#1": [[11,16],[17,19]],
  "eu/forz-eu/navi#1": [],
  "eu/navi-eu/forz#1": [[16,10],[16,8]],
  "eu/nip-eu/ence#1": [[16,8],[9,16],[16,7]],
  "eu/anon-eu/ence": [[9,16],[10,16]],
  "eu/anon-eu/entr": [[16,14]],
  "eu/anon-eu/spir": [[14,16],[16,12],[7,16]],
  "eu/asg-eu/sang": [[16,9],[16,5]],
  "eu/astr-eu/asg": [[16,5]],
  "eu/astr-eu/ence": [[16,11]],
  "eu/astr-eu/g2": [[10,16],[16,7],[17,19]],
  "eu/astr-eu/heet": [[13,16],[16,14],[16,11]],
  "eu/big-eu/saw": [[16,5]],
  "eu/bne-eu/asg": [[16,2],[16,13]],
  "eu/bne-eu/g2": [[10,16]],
  "eu/cphf-eu/sinn": [[16,9]],
  "eu/cphf-eu/spro": [[16,11]],
  "eu/ef-eu/dig": [[22,20]],
  "eu/ence-eu/heet": [[16,8]],
  "eu/ence-eu/spir": [[16,11]],
  "eu/entr-eu/bne": [[17,19]],
  "eu/entr-eu/end": [[16,3]],
  "eu/entr-eu/heet": [[10,16],[9,16]],
  "eu/faze-eu/big": [[7,16],[14,16]],
  "eu/faze-eu/ef": [[16,4]],
  "eu/faze-eu/mouz": [[16,5],[16,11]],
  "eu/fnat-eu/ef": [[12,16],[16,12],[13,16]],
  "eu/fnat-eu/saw": [[10,16]],
  "eu/g2-eu/anon": [[16,19]],
  "eu/g2-eu/sang": [[16,10]],
  "eu/gl-eu/dig": [[8,16],[13,16]],
  "eu/gl-eu/fnat": [[5,16]],
  "eu/gl-eu/forz": [[14,16]],
  "eu/heet-eu/sinn": [[11,16]],
  "eu/heet-eu/spro": [[16,5]],
  "eu/hero-eu/fnat": [[16,9]],
  "eu/hero-eu/forz": [[16,9]],
  "eu/hero-eu/navi": [[11,16],[16,13],[16,9]],
  "eu/mouz-eu/og": [[12,16]],
  "eu/mouz-eu/quaz": [[16,4]],
  "eu/navi-eu/og": [[16,5]],
  "eu/navi-eu/quaz": [[16,12]],
  "eu/navi-eu/vita": [[16,13],[16,13]],
  "eu/nip-eu/bne": [[16,11]],
  "eu/nip-eu/cphf": [[17,19],[10,16]],
  "eu/nip-eu/end": [[16,8]],
  "eu/nip-eu/sinn": [[16,8],[16,11]],
  "eu/og-eu/ef": [[14,16],[14,16]],
  "eu/og-eu/forz": [[17,19]],
  "eu/og-eu/uniq": [[16,10],[16,11]],
  "eu/outs-eu/big": [[8,16]],
  "eu/outs-eu/dig": [[10,16],[19,17],[16,10]],
  "eu/outs-eu/mouz": [[12,16]],
  "eu/outs-eu/uniq": [[16,2]],
  "eu/play-eu/anon": [[16,10]],
  "eu/play-eu/astr": [[16,13],[12,16],[16,8]],
  "eu/play-eu/sang": [[16,7]],
  "eu/quaz-eu/uniq": [[16,10],[7,16],[5,16]],
  "eu/saw-eu/forz": [[16,14],[3,16],[5,16]],
  "eu/saw-eu/outs": [[14,16],[16,10],[16,19]],
  "eu/saw-eu/uniq": [[16,6]],
  "eu/sinn-eu/bne": [[16,10],[6,16],[14,16]],
  "eu/sinn-eu/spir": [[16,6]],
  "eu/spir-eu/asg": [[16,6]],
  "eu/spir-eu/end": [[16,8],[16,14]],
  "eu/spro-eu/end": [[16,19],[16,7],[14,16]],
  "eu/vita-eu/dig": [[16,6]],
  "eu/vita-eu/ef": [[16,11]],
  "eu/vita-eu/faze": [[14,16]],
  "eu/vita-eu/mouz": [[13,16],[16,11],[16,13]],
}
