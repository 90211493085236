export const Scores = {
  "00-arct":[[16,13],[19,16]],
  "00-flam":[[14,16]],
  "00-paqu":[[17,19]],
  "00-plan":[[18,22],[16,5],[16,6]],
  "1win-ikla":[[16,14]],
  "1win-vipe":[[15,19]],
  "4orm-los":[[6,16],[7,16]],
  "500-falc":[[16,10],[17,19],[8,16]],
  "500-forz":[[16,9],[12,16],[10,16]],
  "9ine-ef":[[25,23]],
  "9ine-itb":[[16,10],[10,16],[16,13]],
  "9ine-mixf":[[16,7]],
  "9z-intz":[[16,3]],
  "9z-red":[[11,16]],
  "anon-jano":[[14,16],[12,16]],
  "apek-500":[[16,7],[9,16],[16,3]],
  "apek-inat":[[16,13]],
  "arct-fusc":[[12,16]],
  "astr-ldlc":[[14,16]],
  "astr-tur":[[16,2]],
  "atk-4orm":[[16,14]],
  "atk-stri":[[14,16]],
  "auro-500":[[7,16]],
  "auro-ecst":[[16,11]],
  "auro-jano":[[16,2]],
  "best-fusc":[[16,7]],
  "best-odk":[[16,12],[16,11]],
  "col-mibr":[[10,16],[16,5],[16,10]],
  "col-reig":[[16,10]],
  "col-yur":[[16,9]],
  "cori-flam":[[8,16]],
  "cori-odk":[[23,25]],
  "cori-win":[[16,4]],
  "cphf-ef":[[11,16]],
  "cphf-mixf":[[9,16]],
  "dtn-atk":[[16,7]],
  "eg-reig":[[16,8]],
  "eg-yur":[[16,19]],
  "eye-anon":[[12,16]],
  "eye-hnrs":[[16,4],[16,12]],
  "falc-apek":[[8,16]],
  "falc-hnrs":[[16,5]],
  "falc-ig":[[19,17]],
  "flux-9z":[[16,4],[16,11]],
  "flux-flam":[[16,3]],
  "flux-impe":[[11,16],[9,16]],
  "flux-paqu":[[16,9]],
  "flyt-reig":[[16,5],[16,6]],
  "forz-ecst":[[14,16],[16,12],[16,12]],
  "forz-heet":[[7,16]],
  "forz-jano":[[8,16]],
  "fusc-cori":[[16,14],[16,11]],
  "fusc-flam":[[12,16],[6,16]],
  "g2-ldlc":[[16,5]],
  "g2-tur":[[16,5]],
  "gl-anon":[[16,14]],
  "gl-heet":[[13,16],[16,13],[16,14]],
  "gl-ig":[[16,11]],
  "havu-b8":[[22,19]],
  "havu-ef":[[10,16]],
  "havu-mixf":[[16,7],[16,5]],
  "havu-tric":[[13,16]],
  "heet-auro":[[6,16],[16,12],[9,16]],
  "heet-mont":[[16,13],[13,16],[16,19]],
  "ig-eye":[[16,14]],
  "ig-forz":[[11,16],[14,16]],
  "ikla-b8":[[16,11],[14,16],[14,16]],
  "ikla-itb":[[9,16]],
  "impe-intz":[[16,9]],
  "impe-red":[[16,8]],
  "inat-eye":[[16,12],[16,10]],
  "inat-hnrs":[[16,8]],
  "inat-jano":[[16,12],[16,14]],
  "intz-00":[[3,16],[11,16]],
  "intz-win":[[16,2],[16,13]],
  "itb-ef":[[6,16],[12,16]],
  "itb-havu":[[11,16],[16,8],[16,8]],
  "itb-vipe":[[16,12]],
  "ldlc-1win":[[10,16]],
  "ldlc-b8":[[3,16],[16,6],[6,16]],
  "los-myth":[[12,16]],
  "los-vend":[[12,16]],
  "mibr-dtn":[[16,4]],
  "mibr-eg":[[16,9],[16,11]],
  "mibr-flyt":[[16,13]],
  "mont-500":[[17,19]],
  "mont-ecst":[[16,14]],
  "mont-inat":[[16,8]],
  "myth-eg":[[7,16],[2,16]],
  "myth-flyt":[[99,0],[13,16],[16,14]],
  "eg-los#1":[[14,16],[13,16]],
  "noun-atk":[[8,16],[16,12],[16,10]],
  "noun-dtn":[[11,16]],
  "noun-flyt":[[16,7]],
  "odk-9z":[[6,16],[11,16]],
  "odk-paqu":[[16,14],[14,16],[12,16]],
  "og-anon":[[16,13]],
  "og-auro":[[16,11],[16,5]],
  "og-heet":[[10,16]],
  "og-jano":[[19,17]],
  "pain-4orm":[[16,12]],
  "pain-stri":[[16,2]],
  "plan-arct":[[16,11]],
  "plan-best":[[10,16]],
  "plan-fusc":[[15,19]],
  "plan-shar":[[3,16],[16,12],[16,5]],
  "red-cori":[[11,16],[10,16]],
  "red-paqu":[[9,16]],
  "00-9z#1":[[16,13]],
  "saw-astr":[[7,16],[19,17],[14,16]],
  "saw-b8":[[16,10]],
  "saw-mixf":[[16,8]],
  "saw-tric":[[11,16]],
  "shar-9z":[[9,16]],
  "shar-odk":[[9,16]],
  "shar-win":[[16,9]],
  "stri-los":[[5,16],[5,16]],
  "stri-noun":[[6,16]],
  "timb-dtn":[[11,16],[12,16]],
  "timb-los":[[17,19],[16,10],[5,16]],
  "timb-myth":[[16,2]],
  "timb-pain":[[12,16],[8,16]],
  "tric-1win":[[9,16],[9,16]],
  "tric-b8":[[11,16],[9,16]],
  "tric-g2":[[10,16],[7,16]],
  "tur-cphf":[[14,16],[16,14],[16,11]],
  "vend-atk":[[7,16],[16,19]],
  "vend-eg":[[8,16]],
  "vend-timb":[[11,16]],
  "vipe-astr":[[6,16]],
  "vipe-saw":[[16,10],[16,14]],
  "vipe-tur":[[16,10],[13,16],[16,13]],
  "yur-myth":[[16,12]],
  "yur-noun":[[16,10],[16,8]],
  "tric-jano":[[17,19],[14,16]],
  "500-havu":[[16,14],[16,10]],
  "jano-saw#1":[[14,16],[12,16]],
  "saw-heet":[[16,12],[16,10]],
}
